import React from 'react'
import Link from '../../components/link'
import Posts from '../../assets/news'
import '../home/HomeTemplate.scss'

const NewsPage = () => {
  return (
    <>
      <div className="row subtitleRow">
        <span className="subtitle">News</span>
      </div>
      <div className="scrollSection">
        {Posts.map((post) => (
          <Link key={post.slug} to={post.slug}>
            {post.title}
          </Link>
        ))}
      </div>
    </>
  )
}

export default NewsPage
