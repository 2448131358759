import React from 'react'
import Page from '../templates/components/Page'
import NewsPage from '../templates/news/News'

const News = () => {
  return (
    <Page>
      <NewsPage />
    </Page>
  )
}

export default News
