const NewsPosts = [
  {
    title: 'Announcing the Future Atomix Connoisseurs',
    slug: 'announcing-the-future-atomix-connoisseurs',
    date: 'August 16, 2022',
    directory: 'article-atomix',
  },
]

export default NewsPosts
